import { PublicClientApplication } from "@azure/msal-browser";
const baseURL = import.meta.env.VITE_BASE_URL;

export const msalConfig = {
  auth: {
    clientId: "c973babf-0b74-488e-b1fb-48db4d6d9945",
    authority:"https://login.microsoftonline.com/b143cada-947c-4d95-ae4e-cf9b2b04629e",
    postLogoutRedirectUri: baseURL,
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
};

export const graphScopes = {
  scopes: ["user.read", "openid", "profile"],
};

const myMSALObj = new PublicClientApplication(msalConfig);
myMSALObj.initialize();

export { myMSALObj };
