<template>
  <v-layout style="padding: 2%">
    <v-bottom-navigation v-model="value" active color="primary">
      <!-- "Générale" button -->
      <v-btn :class="{ 'active-btn': displayGeneral }" @click="displayGeneralSettings">
        Générale
      </v-btn>
      <!-- "Model de commentaire" button -->
      <v-btn @click="displayModelCommentSettings">
        Model de commentaire
      </v-btn>
    </v-bottom-navigation>
  </v-layout>

  <v-container v-if="displayGeneral">
    <v-form @submit.prevent="updateSettings">
      <!-- Display general settings -->
      <v-row>
        <v-col cols="4" v-for="(setting, index) in settings" :key="index" class="v_div_form">
          <v-text-field
              hide-details="auto"
              :label="setting.label"
              v-model="setting.value"
              :ref="`inputField_${index}`"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn type="submit" color="primary" style="float:right;">Enregistrer</v-btn>
    </v-form>
  </v-container>

  <v-container v-if="displayModelComment">
    <v-form @submit.prevent="updateModel">
      <!-- Display model comment settings -->
      <v-row>
        <v-col cols="6" v-for="(model, index) in modelCommentSettings" :key="index" class="v_div_form">
          <v-text-field
              hide-details="auto"
              :label="model.label"
              v-model="model.content"
              :ref="`modelInputField_${index}`"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn type="submit" color="primary" style="float:right;">Enregistrer</v-btn>
    </v-form>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      value: null,
      settings: [],
      modelCommentSettings: [],
      displayGeneral: true,
      displayModelComment: false
    };
  },
  mounted() {
    this.fetchSettings();
    this.fetchModelCommentSettings();
  },
  methods: {
    async fetchSettings() {
      try {
        const {data} = await this.axios.get('setting');
        this.settings = data.map(setting => ({
          id: setting.id,
          key: setting.key,
          value: setting.value,
          label: this.getFrenchLabel(setting.key)
        }));
      } catch (error) {
        console.error('Error fetching setting list data:', error);
      }
    },
    async updateSettings() {
      try {
        const formData = {};
        this.settings.forEach(setting => {
          formData[setting.key] = setting.value;
        });
        const response = await this.axios.post('setting/update', formData);
        if (response.data.success) {
          window.location.reload();
        } else {
          console.log('Error updating settings');
        }
      } catch (error) {
        console.error('Error updating settings:', error);
      }
    },
    async fetchModelCommentSettings() {
      try {
        const {data} = await this.axios.get('/model');
        this.modelCommentSettings = data.map(model => ({
          id: model.id,
          title: model.title,
          content: model.content,
          label: this.getFrenchLabel(model.title)
        }));
      } catch (error) {
        console.error('Error fetching model comment settings:', error);
      }
    },
    async updateModel() {
      try {
        const formData = {};
        this.modelCommentSettings.forEach(model => {
          formData[model.title] = model.content;
        });
        const response = await this.axios.post('model/update', formData);
        if (response.data.success) {
          this.displayModelComment = true;
        } else {
          console.log('Error updating model comment settings');
        }
      } catch (error) {
        console.error('Error updating model comment settings:', error);
      }
    },
    displayGeneralSettings() {
      this.displayGeneral = true;
      this.displayModelComment = false;
    },
    displayModelCommentSettings() {
      this.displayGeneral = false;
      this.displayModelComment = true;
    },
    getFrenchLabel(key) {
      const labels = {
        gitlab_url: 'URL de Gitlab',
        gitlab_token: 'Clé d\'accèss gitlab',
        gitlab_web_hook_secret: 'Web hook secret de gitlab',
        grc_token: 'Clé d\'accèss grc',
        grc_web_hook_secret: 'Web hook secret de grc',
        web_hook: 'Web hook url de grc',
        web_hook_secret: 'Web hook secret de grc',
        api_access_token: 'Accèss api token',
        sender_name: 'Nom de l\'expéditeur',
        sender_email: 'Email de l\'expéditeur',
        mail_revision_created: 'Email de création de révision est crée',
        mail_user_created: "Email de création d'utilisateur est crée",
        mail_project_access_granted: "Accès au projet de messagerie accordé",
        user_created_notification: 'Notification créée par l\'utilisateur',
        project_access_granted_notification: 'Notification d\'accès au projet accordé',
        grc_host: 'URL du hôte grc',
        task_type: 'Type de la tâche',
        images_cache_version: 'Version du cache d\'images',
        new_revision_notification: 'Nouvelle notification de révision',
        add_revision: 'Envoyer des nouveaux fichiers dans git',
        accept_maquette: 'Maquette(s) acceptée(s)',
        add_comment: 'Cliquer sur button informer graphiste / envoyer mes commentaires',
        lunch_fix: 'Contenu du commentaire lancer les corrections'

      };
      return labels[key] || key;
    }
  }
}
</script>
