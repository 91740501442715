<template>
    <v-card class="pa-2">
        <v-select v-model="selectedValue" :items="options" attach :label="selectLabel" multiple class="select-filter" @change="handleChange" >
            <template v-slot:prepend-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field v-model="searchTerm" placeholder="Search" @input="filterOptions"></v-text-field>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
        </v-select>
    </v-card>
</template>
  
<script>
export default {
    props: {
        value: { // v-model binding
            type: [Array, String, Number],
            default: () => []
        },
        items: { // :items binding
            type: Array,
            default: () => []
        },
        label: { // label for the select
            type: String,
            default: "Label"
        },
        multiple: { // whether it's multiple select or not
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedValue: this.value,
            searchTerm: "",
            options: this.items,
        };
    },
    mounted() {
         this.optionsCopy = [...this.options];

    }, 
    methods: {
         filterOptions() {
            if (!this.searchTerm) {
                this.options = this.optionsCopy;
            }
            this.options = this.optionsCopy.filter((project) => {
                return project.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
            });
       } , 
       handleChange() {
            this.$emit('input', this.selectedValue); // Emit input event with updated values
        }

        
    },

    watch: {
    selectedValue: {
        handler(newSelectedValue) {
            this.$emit('input', newSelectedValue);
            
        },
        deep: true // This is necessary if selectedValue is an array
    }
},
    computed: {
    selectLabel() {
      return this.label;
    }
  }
};
</script>
  
  <!-- You can add styles for the select in the same file or use an external stylesheet -->
<style lang="scss" scoped>
@mixin button-style {
    text-align: left;
    font: normal normal normal 13px/18px Roboto;
    letter-spacing: 0px;
    color: #494949;
    background: #F7F4F4 !important;

}

@mixin text-style {
    opacity: 1;
    text-align: left;
    font: normal normal normal 13px/18px Roboto;
    letter-spacing: 0px;

}

.v-select .v-input__control {
    height: 40px;
    width: 150px;
    border-radius: 5px;
    @include button-style;
}

.v-field-label {
    padding-bottom: 1rem !important;
    color: #494949 !important;
    text-align: center !important;
    ;
}


//icon v-field
.v-field__append-inner {
    padding-bottom: 1rem !important;

}

.select-filter .v-select__selection {
    display: none !important
}
</style>