import { defineStore } from "pinia";
import axios from "axios";

export const useUserStore = defineStore("userStore", {
  state: () => {
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null; ;
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
    const refresh_token = localStorage.getItem("refresh-token") ? JSON.parse(localStorage.getItem("refresh-token")) : null;
    return {user , token , refresh_token }
    
  },
  getters: {
    isAuthenticated: (state) => {
      return !!state.token && !!state.refresh_token ; ;
    },
  },
  actions: {
    getCurrentUser() {},
    setToken(token){
      this.token = token 
    } ,
    setRefreshToken(refresh_token){
      this.refresh_token = refresh_token 
    },
    getRefreshToken(){
     return this.refresh_token 
    },
    setUser(user){
      this.user = user 
    },
    logout() {
      try {
        this.token = null  ; 
        this.refresh_token = null
        this.user = null
        localStorage.removeItem("token");
        localStorage.removeItem("refresh-token");
        localStorage.removeItem("isAzure");
        localStorage.removeItem("user");


      } catch (error) {
        // Handle login error
        console.error("logout  failed:", error);
      }
    },
   
  
   
  },
});
