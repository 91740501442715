// CommentService.ts
import axios from "axios";

const COMMENT_URL = "comment/"
export const UNTREATED = 1;
export const TREATED = 2;
export const REJECTED = 3;
export const ADD_COMMENT_MSG = 3;
export const VALIDATE_REVISION_MSG = 2;
export const LUNCH_FIX_MSG = 4;

export function commentService() {
  async function createComment(comment, pin) {
    try {
      const response = await axios.post(COMMENT_URL + `new`, {comment, pin});
      return response.data;
    } catch (error) {
      console.error("createComment error:", error);
      throw new Error("Failed to create comment");
    }
  }


  async function getCommentsByRevisionId(revId, status = 0) {
    try {
      let url = `comment/revision/${revId}`;
      const data = {status: status};
      const response = await axios.post(url, data);
      return response.data; // Return the response data
    } catch (error) {
      console.error("getCommentsByRevisionId  failed:", error);
      throw new Error("Failed to get comments by revision ID");
    }
  }

  async function getStatusComments() {
    try {
      const response = await axios.get(`status/comments`);
      return response.data; // Return the response data
    } catch (error) {
      console.error("getStatusComments  failed:", error);
      throw new Error("Failed to get Status Comments");
    }
  }

  async function editComment(commentId, comment) {
    try {
      const response = await axios.put(`comment/${commentId}/edit`, comment);
      return response.data; // Return the response data
    } catch (error) {
      console.error("edit Comment failed:", error);
      throw new Error("Failed to editComment");
    }
  }

  async function addCommentGrc(taskId, payload) {
    try {

      const response = await axios.post(`task/${taskId}/addCommentGrc`, payload);
      return response.data; // Return the response data
    } catch (error) {
      console.error("edit Comment failed:", error);
      throw new Error("Failed to editComment");
    }
  }

  return {createComment, getCommentsByRevisionId, getStatusComments, editComment, addCommentGrc};
}
  