import { createApp, watch } from "vue";
import App from "./components/App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import PortalVue from "portal-vue";
import VueObserveVisibility from "vue3-observe-visibility";
import { createPinia } from "pinia";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { useUserStore } from "./store/userStore";
import filters from './utils/filters';

import Emitter from 'tiny-emitter';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import translate from "@/utils/translate.js";

const vuetify = createVuetify({
  components,
  directives,
});
const app = createApp(App)
const pinia = createPinia();

app.config.globalProperties.$emitter = new Emitter();

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
axios.defaults.withCredentials = false;
axios.interceptors.request.use((config) => {
  const userStore = useUserStore();
  if (!config.url.includes('/refresh')) {

  if (userStore.isAuthenticated) {
    config.headers["Authorization"] = "Bearer " + userStore.token;
  }}
  return config;
});

app.config.globalProperties.$filters = filters;
app.config.globalProperties.translate = translate;
watch(
  pinia.state,
  (state) => {
    if (state.userStore ) {
      localStorage.setItem("token", JSON.stringify(state.userStore.token));
      localStorage.setItem("refresh-token",JSON.stringify(state.userStore.refresh_token));
      localStorage.setItem("user", JSON.stringify(state.userStore.user));
      if (state.taskStore ) {
        localStorage.setItem("selectedTask", JSON.stringify(state.taskStore.selectedTask));
        localStorage.setItem("taskCommentsStatus", JSON.stringify(state.taskStore.taskCommentsStatus));

      }
      
    }
   
    
  },
  { deep: true }
);
app.use(router);
pinia.use(piniaPluginPersistedstate)

app.use(pinia);
app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);
app.use(VueObserveVisibility);
app.use(PortalVue);
app.use(vuetify);
app.mount("#App");
