<template lang="">
    <div class="v-application__wrap ">
    <router-view />
    </div>
</template>
<script>

export default {


  }


</script>

<style lang="scss">
.v-application__wrap {
  flex: 1 1 auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}
</style>