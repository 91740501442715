import {createRouter, createWebHistory} from 'vue-router'
import Home from '../pages/Home.vue'
import Login from '../pages/login/login.vue'
import WebCreationList from '../pages/web-creation/WebCreationList.vue'

import PageNotFound from '../pages/NotFound.vue'
import WebCreationDetails from '../pages/web-creation/WebCreationDetails.vue'
import {useUserStore} from '../store/userStore';
import Setting from "@/pages/setting/SettingForm.vue";
import {accountService} from "../services/account.service";
import DialogComponent from '../pages/web-creation/DialogComponent.vue'
import DialogLockComponent from "@/pages/web-creation/DialogLockComponent.vue";
import ManualSyncForm from "@/pages/ManualSync/ManualSyncForm.vue";

export const routes = [
  {
    name: 'home', path: '/home', component: Home, meta: {requiresAuth: true},
    children: [
      {name: 'Projects', path: '/projets', component: WebCreationList},
      {name: 'ProjectDetails', path: '/projets/:id', component: WebCreationDetails, props: true},
      {name: 'Settings', path: '/setting', component: Setting},
      {
        name: 'TaskDetails', path: '/projets/:id/taskId/:taskId', component: DialogComponent, props: route => ({
          taskId: route.params.taskId, project: route.params.id
        })
      },
      {
        name: 'RevisionStatus', path: '/projets/:id/taskId/:taskId', component: DialogLockComponent, props: route => ({
          taskId: route.params.taskId, project: route.params.id, status: route.params.status
        })
      },
      {name: 'ManualSync', path: '/syncManual', component: ManualSyncForm},


    ]

  },
  {
    name: 'login', path: '/', component: Login, meta: {hideForAuth: true}
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: PageNotFound,
    meta: {
      requiresAuth: false
    }
  }

]
const router = createRouter({
  history: createWebHistory(),
  base: import.meta.env.VITE_BASE_URL,
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const isAuthenticated = userStore.isAuthenticated;


  if (to.matched.some(record => record.meta.requiresAuth)) {
    sessionStorage.setItem('redirectAfterLogin', to.fullPath);
    if (!isAuthenticated) {
      next({name: 'login'});
    } else {
      try {
        await accountService().refreshToken();
        next();
      } catch (error) {
        console.error('Token refresh failed:', error);
        next({name: 'login'}); // Redirect to login page if token refresh fails
      }
    }
  } else if (to.matched.some(record => record.meta.hideForAuth)) {
    if (isAuthenticated) {
      next({name: 'Projects'});
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router
