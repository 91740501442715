import { defineStore } from 'pinia'

export const useProjectStore = defineStore('store', {
  state: () => ({
    selectedRow: null,
  }),
  actions: {
    setSelectedRow(row) {
      this.selectedRow = row;
    },
  },

});