<template>
    <v-container>
       <h1>404</h1>
    </v-container>
</template>

<script>

export default {
    data: () => ({

    })
}
</script>
