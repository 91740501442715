<template class="global">
  <v-container>
    <v-row>
      <v-col v-if="!isLoadingData  && !user.is_client" class="first-block" cols="2">
        <v-img v-if="projectDetails.image" :src="getImageSource(projectDetails.image)" aspect-ratio="16/9"
               class="img-project"
               cover></v-img>
        <v-img v-else aspect-ratio="16/9" class="img-project" cover
               src="https://cdn.vuetifyjs.com/images/parallax/material.jpg">
        </v-img>
        <div class="project-title">{{ projectDetails.company_name }}</div>
        <v-divider class="divider"></v-divider>
        <h3 class="users-text">Assigné à :</h3>
        <v-avatar v-for="(user, index) in uniqueAssignedUsers" :key="index">
          <v-avatar :image="getAvatar(user.avatar)"></v-avatar>
          <v-tooltip activator="parent" location="bottom" style="z-index: 999;">{{ user.username }}</v-tooltip>
        </v-avatar>
        <h3 class="users-text">Contacts clients :</h3>
        <v-avatar v-for="(user, index) in uniqueClientContacts" :key="index">
          <v-avatar :image="getAvatar(user.avatar)"></v-avatar>
          <v-tooltip activator="parent" location="bottom" style="z-index: 999;">{{ user.username }}</v-tooltip>
        </v-avatar>
        <h3 class="users-text">Autres contacts clients :</h3>
        <v-avatar v-for="(user, index) in uniqueOtherClientContacts" :key="index">
          <v-avatar :image="getAvatar(user.avatar)"></v-avatar>
          <v-tooltip activator="parent" location="bottom" style="z-index: 999;">{{ user.username }}</v-tooltip>
        </v-avatar>
        <v-divider></v-divider>
        <h3 class="users-text">
          Dernières mise à jour
          {{ $filters.formatDate(this.projectDetails.updated_at) }}
        </h3>
      </v-col>
      <v-col :cols="user.is_client ? '12' :'10'" class="second-block">
        <v-breadcrumbs :items="items">
          <template v-slot:title="{ item }">
            <v-breadcrumbs-item :class="{
              'v-breadcrumbs-item-clicked': shouldApplyPointer(item),
            }" :href="item.href" @click="goBack(item)">
              {{ item.title }}
            </v-breadcrumbs-item>
          </template>
          <template v-slot:divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
          </template>
        </v-breadcrumbs>
        <v-select v-model="itemsPerPage" :items="numbers" bg-color="#FFFFFF" class="items-per-page"
                  variant="outlined"></v-select>

        <v-skeleton-loader v-if="isLoadingData" type="table"></v-skeleton-loader>
        <v-data-table v-else :header-props="{ class: 'custom-header' }" :headers="headers" :items="paginatedItems"
                      :items-per-page="itemsPerPage" :sort-by="[{ key: 'id', order: 'asc' }]">
          <template v-slot:item="{ item}">
            <tr :style="canOpenDialog(item) ? 'cursor: pointer' : '' " class="tr "
                @click="canOpenDialog(item) && openDialog(item , projectDetails.title)"
            >
              <td>{{ item.id }}</td>
              <td :style="!canOpenDialog(item) ? 'color: rgba(137,137,137,0.93)' : ''" class="tr-title">
                {{ item.title }}
                <v-tooltip v-if="!canOpenDialog(item)" activator="parent" location="bottom">
                  La tâche n'a aucune maquette
                </v-tooltip>
              </td>

              <td class="tr-format">
                <span v-for="view in views" :key="view.name">
                        <template v-if="item && item[view.required]">
                        <v-btn class="transparent-btn" icon>
                        <v-icon :color="getIconMessageColor(item[view.required], item.files, view.name).color"
                                class="format-icon">
                                {{ view.icon }}
                        </v-icon>
                        <v-tooltip activator="parent" location="bottom">
                                {{ getIconMessageColor(item[view.required], item.files, view.name).message }}
                        </v-tooltip>
                        </v-btn>
                        </template>
                </span>

              </td>
              <td>
                {{
                  item.updated_at ? $filters.formatDate(item.updated_at) : "-"
                }}
              </td>
              <td>
                <span
                    :style="{ color: `${getTaskStatusInfo(item.status).color}` , border: `1px solid ${getTaskStatusInfo(item.status).color}`}"
                    class="tr-status">
                  {{ getTaskStatusInfo(item.status).title }}
                </span>
              </td>
              <!-- nb commentaires traiter -->
              <td>
              </td>
              <td v-if="!user.is_client" @click.stop="openAnotherDialog(item)">
                <v-menu transition="slide-x-transition" location="start" :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn class="transparent-btn js-toggle-lock" icon :disabled="!hasRequiredRevisionStatus(item)"
                           v-bind="props">
                      <v-icon class="mx-2 first-block-icon">mdi-lock-outline</v-icon>
                    </v-btn>
                  </template>
                  <LockDialog :selectedTask="item.id" :taskName="item.title"/>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>

        <template v-if="!isLoadingData && paginatedItems">
          <!-- Move Pagination inside v-if -->
          <Pagination :currentPage="currentPage" :itemsPerPage="itemsPerPage" :pages="pages1"
                      :totalItems="paginatedItems.length" :totalPages="totalPages" @previous-page="previousPage"
                      @next-page="nextPage"
                      @go-to-page="goToPage"></Pagination>
        </template>
      </v-col>
    </v-row>
  </v-container>

  <!-- modal template end-->
</template>

<script>
import Pagination from "../../components/Pagination.vue";
import DialogComponent from "./DialogComponent.vue";
import {useUserStore} from '@/store/userStore.js'
import {BE_BASE_URL} from '@/config/config.js';
import LockDialog from './DialogLockComponent.vue';

export default {
  props: ["id"],
  name: "Project",
  components: {
    Pagination,
    DialogComponent,
    LockDialog
  },

  data() {
    return {
      items: [
        {title: "Projets", disabled: false, href: "/projets"},
        {title: "", disabled: false, href: ""},
      ],
      headers: [
        {title: "ID", align: "start", sortable: false, key: "id"},
        {title: "Maquettes", key: "model"},
        {title: "Formats", key: "formats"},
        {title: "Dernière m.a.j.", key: "updatedAt"},
        {title: "Statut", key: "status"},
        {title: "Commentaires", key: "comments"}
      ],
      itemsPerPage: 15, // Number of items per page
      currentPage: 0,
      totalPages: 0, // Initialize totalPages
      projectDetails: null, // Initialize projectDetails to null
      isLoadingData: true, // Initially set to true to display skeleton loader
      numbers: [5, 10, 15, 20, 25, 50, 100],
      dialog: false,
      selectedTask: null,
      selectedTaskTitle: '',
      taskName: null,
      clientContacts: [],
      uniqueAssignedUsers: [],
      uniqueClientContacts: [],
      uniqueOtherClientContacts: [],
      views: [
        {name: 'desktop', required: 'desktop_view_required', icon: 'mdi-monitor'},
        {name: 'laptop', required: 'laptop_view_required', icon: 'mdi-laptop'},
        {name: 'tablet', required: 'tablet_view_required', icon: 'mdi-tablet'},
        {name: 'mobile', required: 'mobile_view_required', icon: 'mdi-cellphone'},
      ]
    };
  },
  //get path of avatar

  created() {
    const userStore = useUserStore()
    this.user = userStore.user
  },
  watch: {
    projectDetails(newVal) {
      if (newVal && newVal.title) {
        document.title = `${newVal.title} - Espace création STRATIS`;
      }
    }
  },
  methods: {

    getImageSource(item) {
      return `${BE_BASE_URL}${item}`;
    },
    getAvatar(item) {
      if (!item) {
        return "/src/assets/images/logo-stratis.png"
      }
      const avatarPath = 'uploads/users/avatars/';
      return `${BE_BASE_URL}${avatarPath}${item}`;
    },
    async fetchProjectDetails() {
      try {
        const response = await this.axios.post(`project/${this.id}`, {"isArchived": 0});
        this.projectDetails = response.data;
        //this.items[1].title = this.projectDetails.company_name;
        this.items[1].title = this.projectDetails.title;
        this.isLoadingData = false; // Update isLoadingData to false when data loading is complete
        this.assignedUsers = this.projectDetails.usersProjects
        .filter(userProject => userProject.isManager && !userProject.isPrimaryClient && !userProject.isRelatedClient)
        .map(userProject => userProject.user)
        .filter(user => user); // Filter out empty users

        this.uniqueAssignedUsers = Array.from(new Set(this.assignedUsers.map(user => user.id)))
        .map(id => this.assignedUsers.find(user => user.id === id))
        .filter(user => user); // Ensure no empty users in unique list

        this.clientContacts = this.projectDetails.usersProjects
        .filter(userProject => !userProject.isManager && userProject.isPrimaryClient && !userProject.isRelatedClient)
        .map(userProject => userProject.user)
        .filter(user => user); // Filter out empty users

        this.uniqueClientContacts = Array.from(new Set(this.clientContacts.map(user => user.id)))
        .map(id => this.clientContacts.find(user => user.id === id))
        .filter(user => user); // Ensure no empty users in unique list

        this.otherClientContacts = this.projectDetails.usersProjects
        .filter(userProject => !userProject.isManager && !userProject.isPrimaryClient && userProject.isRelatedClient)
        .map(userProject => userProject.user)
        .filter(user => user); // Filter out empty users

        this.uniqueOtherClientContacts = Array.from(new Set(this.otherClientContacts.map(user => user.id)))
        .map(id => this.otherClientContacts.find(user => user.id === id))
        .filter(user => user); // Ensure no empty users in unique list
      } catch (error) {
        console.error("Error fetching project list data:", error);
      }
    },
    openDialog(item, project_name) {
      document.title = `${item.title} - ${project_name} - Espace création STRATIS`;
      this.selectedTask = item.id;
      this.dialog = true; // Open the dialog
      if (this.dialog) {
        this.$router.push({
          name: 'TaskDetails', params: {id: this.projectDetails.id, taskId: this.selectedTask}

        });
      }

    },
    canOpenDialog(item) {
      return item.files_nb > 0;
    },

    previousPage() {
      this.currentPage--;
    },
    nextPage() {
      this.currentPage++;
    },
    goToPage(page) {
      if (page - 1 !== this.currentPage) {
        this.currentPage = page - 1;
      }
    },
    shouldApplyPointer(item) {
      return this.items.indexOf(item) === 0 || this.items.indexOf(item) === 1;
    },
    goBack(item) {
      if (item.title === this.items[1].title) {
        this.$router.back();
      }
    },
    hasRequiredRevisionStatus: function (item) {
      return item.files.length > 0 && item.files.some(file =>
          file.revisions.some(revision => [5, 6].includes(revision.status.id))
      );
    },
    openAnotherDialog(item) {
      if (this.hasRequiredRevisionStatus(item)) {
        this.selectedTask = item.id;
        this.taskName = item.title;
        this.lockDialog = true;
      }
    },
    updatedHeaders() {
      // Add 'Edit' header if the user is an admin
      if (this.user.is_client == null) {
        this.headers = [
          ...this.headers, // Keep existing headers
          {title: "Déverrouiller", align: "start", sortable: false, key: "edit"},
        ];
      }
    },
    getIconMessageColor(view, files, name) {
      let color;
      let message = '';

      const latestFile = files
      .filter(file => file.view === name)
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          [0];


      const latestRevision = latestFile && latestFile.revisions.length > 0
          ? latestFile.revisions.slice(-1)[0]
          : null;

      if (latestRevision === null && view) {
        color = "rgba(137,137,137,0.93)";
        message = "Maquette non disponible";
      }
      if (latestRevision !== null && latestRevision.visible && view) {
        color = "#05A9F4";
        message = !this.user.is_client ? "Maquette visible client" : "Maquette disponible";
      }

      if (latestRevision !== null && !latestRevision.visible && view) {
        color = "#000000";
        message = !this.user.is_client ? "Maquette non-visible client" : "Maquette non disponible";
      }

      return {color, message};
    },
    getTaskStatusInfo(status) {
      let title = "";
      let color = "";
      if (this.user.is_client) {
        title = status.customerTitle;
        color = status.customerColor;
      } else {
        title = status.title;
        color = status.color;
      }
      return {title: title, color: color}
    }
  },

  mounted() {
    this.fetchProjectDetails();
    this.updatedHeaders();
  },
  computed: {
    paginatedItems() {
      const startIndex = this.currentPage * this.itemsPerPage;
      return this.projectDetails.tasks.slice(
          startIndex,
          startIndex + this.itemsPerPage
      );
    },
    pages1() {
      this.totalPages = Math.ceil(
          this.projectDetails.tasks.length / this.itemsPerPage
      );
      return Array.from({length: this.totalPages}, (_, i) => i + 1);
    },

  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.global {
  color: #000000;
  letter-spacing: 0px;
  opacity: 1;

}

.v-row {

  height: 100vh;
}

.v-avatar.v-avatar--density-default {
  height: 37px;
  width: 37px;
}

.v-container {
  max-width: 100%;
  max-height: 100%;
}

.first-block {
  background: #f0f4f7 0% 0% no-repeat padding-box;

  & .img-project {
    width: 288px;
    height: 148px;
    border-radius: 10px;

  }

  & .project-title {
    margin-top: 1rem;
    @include font("Roboto", 20px, bold);
  }

  & .users-text {
    margin-top: 18px;
    margin-bottom: 2px;
    color: #494949;
    font: normal normal normal 13px/18px Roboto;
  }


  & .divider {
    padding-bottom: 5px;
  }
}

.second-block {
  background: white 0% 0% no-repeat padding-box;


  & .v-breadcrumbs {
    padding-left: 0px;
    padding-bottom: 10px;
    @include font("Roboto", 14px, bold);

    & .v-breadcrumbs-item-clicked {
      text-decoration: underline;
      cursor: pointer;
      font-weight: normal;
      /* Reset font weight for clicked items */
    }
  }

  & .tr {
    font: normal normal normal 13px/18px Roboto;
    opacity: 1;


    & .tr-title {
      font-weight: bold;
    }

    & .tr-format {
      text-align: left;

      & .format-icon {
        margin-right: 5px;
      }
    }

    & .tr-status {
      border-radius: 2px;
      padding: 5px;

    }
  }
}

.transparent-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 13px !important;
  z-index: 999;
}

.format-icon {
  margin: 5px; /* Adjust this value as needed */
}
</style>
<style>
.v-img__img {
  height: auto !important;
}
</style>

