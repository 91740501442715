
import axios from "axios";
import { useUserStore } from "../store/userStore";

export function accountService() {

    async function login(email, password) {
        const userStore = useUserStore();

        try {
          const response = await axios.post(
            "login_check",
            { email, password }
          );
          const { token, refresh_token ,Username , avatar ,is_client  ,id} = response.data; // Assuming response data is JSON
          startRefreshTokenTimer(token);

          userStore.setUser({Username ,email , avatar ,is_client ,id }) 
          userStore.setToken(token)  ; 
          userStore.setRefreshToken(refresh_token)
        } catch (error) {
          // Handle login error
          console.error("Login failed:", error);
        }
      }

    async function refreshToken() {
        const userStore = useUserStore();
        try {
      
          const response = await axios.post(
              "token/refresh",
              { refresh_token: userStore.getRefreshToken() });
          const { token, refresh_token } = response.data;
          
          userStore.setToken(token)  ; 
          userStore.setRefreshToken(refresh_token)
          startRefreshTokenTimer(token,refresh_token);
        } catch (error) {
          // Handle login error
          console.error("refresh token   failed:", error);
        }
      }

     function startRefreshTokenTimer(token) {

        // parse json object from base64 encoded jwt token
        const jwtBase64 = token.split(".")[1];
        const jwtToken = JSON.parse(atob(jwtBase64));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
  
        // If the remaining time is positive (token not expired yet), set a timeout to refresh the token
        if (timeout > 0) {
           setTimeout(() => {
            refreshToken(); // Call refreshToken after the timeout
          }, timeout);
        } else {
          // Token already expired, refresh it immediately
          refreshToken();
        }
      }
  
    return { login, refreshToken };
  }