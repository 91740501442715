<template>
  <v-app id="inspire">

    <v-navigation-drawer v-if="!user.is_client" v-model="drawer" class="nav">

      <v-list>
        <v-list-item :title="'Bonjour '+ user.Username">
          <template v-slot:append>
            <v-btn icon="mdi-power" size="small" variant="text"></v-btn>
          </template>
        </v-list-item>
      </v-list>

      <v-list :lines="false" density="compact" nav>
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.route" :value="item">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <Header :toggle-drawer="toggleDrawer"/>

    <v-main>
      <router-view/>
    </v-main>

  </v-app>
</template>

<script>
import Header from '../components/Header.vue';
import {useUserStore} from '../store/userStore';

export default {
  components: {
    Header,
  },

  data() {
    return {
      drawer: false, // State for the navigation drawer
      items: [], // Navigation items
    };
  },

  computed: {
    // Access the user data from the Pinia store
    user() {
      const userStore = useUserStore();
      return userStore.user;
    },
  },

  methods: {
    // Toggle the navigation drawer state
    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    // Set up the navigation items based on the user's role
    setupNavigationItems() {
      this.items = [{text: 'PROJETS', icon: 'mdi-web', route: '/projets'}];
      if (this.user.admin) { // if user is admin grc
        this.items.push(
            {text: 'PARAMÈTRES', icon: 'mdi-cog', route: '/setting'},
            {text: 'SYNCRO. MANUELLE', icon: 'mdi-sync', route: '/syncManual'}
        );
      }
    },
  },

  mounted() {
    // Initialize the navigation items when the component is mounted
    this.setupNavigationItems();
  },
};
</script>
<style lang="scss" scoped>
.v-toolbar__content {
  background: #000;
}

.search-global {
  background: #707070;
  color: #fff;
}

.mdi-menu {
  color: #fff;
}


.nav {
  background: #C90D0F 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 0;
  color: #FFFFFF;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-style: normal normal normal 13px/18px Roboto;
}

.v-application__wrap nav {
  z-index: 0;

}

.v-navigation-drawer nav {
  color: white;
}
</style>