<template>
    <v-card class="pa-2" outlined tile>

        <v-btn class="mb-1 create-web-btn" @click="onClick"> {{ buttonText }}</v-btn>
    </v-card>
</template>
  
<script>
export default {

    props: {
        buttonText: {
            type: String,
            default: "Toutes les Créations web" // Default button text
        }
    },
    methods: {
        onClick() {
            this.$emit('button-click');
        }
    }
};
</script>
  
<style  lang="scss" scoped>
@mixin button-style {
    text-align: left;
    font: normal normal normal 13px/18px Roboto;
    letter-spacing: 0px;
    color: #494949;
    background: #F7F4F4 !important;

}

@mixin text-style {
    opacity: 1;
    text-align: left;
    font: normal normal normal 13px/18px Roboto;
    letter-spacing: 0px;

}

.create-web-btn {
    @include button-style;
    text-transform: capitalize !important;

}

.create-web-btn:hover {
    background: #161616;
}
</style>