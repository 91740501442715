<template>
  <v-app-bar color="black">
    <template v-slot:prepend v-if="!user.is_client">
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
    </template>
    <!-- App bar title with the logo -->
    <v-app-bar-title>
      <v-img :width="126" cover :src="path_logo"></v-img>
    </v-app-bar-title>
    <!-- Spacer to push the search field and icons to the right -->
    <v-spacer></v-spacer>

    <!-- Button for search icon -->
    <v-btn icon>
      <v-icon>
        <!--for test until avatr will be fixed start -->
        <v-avatar :image="path_avatar"></v-avatar>
        <!--for test until avatr will be fixed end -->
        <!-- <v-avatar :image="user.avatar"></v-avatar> -->
      </v-icon>
      <v-tooltip activator="parent" location="bottom">{{ user.Username }}</v-tooltip>
    </v-btn>
    <!-- Button for notification icon -->
    <v-btn icon>
      <v-icon icon="mdi-bell-outline"></v-icon>
      <v-tooltip activator="parent" location="bottom">Notifications</v-tooltip>
    </v-btn>
    <!-- Button for logout  -->
    <v-btn color="white" @click.prevent="logout" density="compact" icon="mdi-logout">
    </v-btn>

  </v-app-bar>
</template>
<script setup>
const props = defineProps({
  toggleDrawer: Function
})

</script>

<script>
import {useUserStore} from '../store/userStore'
import {accountAzureService as accountAzureAPI} from '../services/account_azure.service';
import {BE_BASE_URL, BASE_URL} from '../config/config';

const accountAzureService = accountAzureAPI();
let path_avatar = '';
let path_logo = '';
export default {
  data: () => (
      {
        drawer: null,
        user: null,
        path_avatar: '',
        path_logo: ''
      }),

  async created() {
    const userStore = useUserStore()
    this.user = userStore.user;
    path_avatar = BE_BASE_URL + this.user.avatar;
    path_logo = BASE_URL + 'assets/images/stratis-creation.svg';
  },
  methods: {
    async logout() {
      const userStore = useUserStore()
      // if(localStorage.getItem("isAzure")){
      //   await accountAzureService.logoutAzure();
      // }
      // // else {
      userStore.logout();
      this.$router.push("/");

      // }
    }
  }
}
</script>

<style lang="scss">

.search-global {
  background: #707070;
  color: #fff;
}

</style>